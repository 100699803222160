<template>
  <div class="incident-report-container">
    <div class="card">
      <div class="card-body">
        <!-- Incident Report Form -->
        <form @submit.prevent="createIncident">
          <!-- Date and Time Field -->
          <div class="form-group row mb-3">
            <label for="incidentDate" class="col-sm-2 col-form-label">Incident Date <span
                class="text-danger">*</span></label>
            <div class="col-sm-4">
              <input type="date" class="form-control" id="incidentDate" v-model="incident.date" :max="maxDate" />
            </div>
            <label for="incidentTime" class="col-sm-2 col-form-label">Incident Time <span
                class="text-danger">*</span></label>
            <div class="col-sm-4">
              <input type="time" class="form-control" id="incidentTime" v-model="incident.time" />
            </div>
          </div>

          <!-- Category Field -->
          <div class="form-group row mb-3">
            <label for="category" class="col-sm-2 col-form-label">Category <span class="text-danger">*</span></label>
            <div class="col-sm-10">
              <div class="form-check" v-for="option in categoryOptions" :key="option.value">
                <input class="form-check-input" type="checkbox" :value="option.value" v-model="incident.category">
                <label class="form-check-label" for="defaultCheck1">
                  {{ option.text }}
                </label>
              </div>
            </div>
          </div>

          <!-- Description Field -->
          <div class="form-group row mb-3">
            <label for="description" class="col-sm-2 col-form-label">Description <span
                class="text-danger">*</span></label>
            <div class="col-sm-10">
              <textarea class="form-control" id="description" rows="5" v-model="incident.description"
                @input="checkDescriptionLength"></textarea>
              <div class="d-flex justify-content-between align-items-center">
                <small id="descriptionHelp" class="form-text text-muted">
                  Minimum 100 characters required.
                </small>
                <small class="text-muted">{{ descriptionCharacterCount }}/100</small>
              </div>
              <!-- Error Alert for Description Character Count -->
              <div v-if="showDescriptionError" class="alert alert-danger mt-2">
                Description must be at least 100 characters.
              </div>
            </div>
          </div>

          <!-- Police Reference Field -->
          <div class="form-group row mb-3">
            <label for="policeRef" class="col-sm-2 col-form-label">Police Ref</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="policeRef" v-model="incident.policeRef" />
            </div>
          </div>

          <!-- Face/People Field -->
          <div class="form-group row mb-3">
            <label for="face" class="col-sm-2 col-form-label">Face(s) <span class="text-danger">*</span></label>
            <div class="col-sm-10">
              <div id="face" :class="['form-control', peopleBoxClass]" style="height: auto;">
                <div>
                  <img :src="faceSas" @contextmenu.prevent alt="Person Image" class="person-image">
                </div>
                <!-- <p>{{ faceId }}</p> -->
              </div>
            </div>
          </div>

          <!-- Evidence Upload Field -->
          <div class="form-group row mb-3">
            <label for="evidence" class="col-sm-2 col-form-label">Evidence <span class="text-danger">*</span></label>
            <div class="col-sm-10">
              <input type="file" class="form-control" id="evidence" @change="handleFileUpload" multiple
                accept="image/png, image/jpeg, application/pdf" required />
            </div>
          </div>

          <!-- Display Added Files and File Descriptions -->
          <div v-for="(file, index) in incident.evidence" :key="index" class="mb-3">
            <div class="row mb-2">
              <div class="col-sm-10">
                <strong>File {{ index + 1 }}:</strong> {{ file.file.name }}
              </div>
              <div class="col-sm-2">
                <!-- Remove File Button -->
                <button type="button" class="btn btn-danger btn-sm" @click="removeFile(index)">
                  Remove
                </button>
              </div>
            </div>
          </div>

          <!-- Declaration Checkbox -->
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="declaration" v-model="incident.accept_declaration">
            <label class="form-check-label" for="declaration">
              I, hereby declare that the information provided above is accurate and truthful to the best of my
              knowledge. I acknowledge and accept that if any information provided is found to be incorrect, or if I
              have knowingly provided incorrect information, I may be held liable for any consequences arising from such
              inaccuracies.<span class="text-danger">*</span>
            </label>
          </div>

          <!-- Submit Button -->
          <button type="submit" class="btn btn-primary" :disabled="!isFormValid || isSubmitting">
            {{ isSubmitting ? 'Submitting...' : 'Submit Incident Report' }}
          </button>
          <!-- Clear Form Button -->
          <button type="button" class="btn btn-outline-secondary btn-lg mx-2" @click="clearForm">Clear Form</button>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import authHeader from "../../services/auth-header";
import { globalState } from './globalState.js';

export default {
  name: 'CreateIncidentReport',
  props: {
    // pendingPersons: String,
    // personIds: String,
    dateFilter: String,
    faceId: String,
    faceSas: String
  },
  data() {
    return {
      categoryOptions: [
        { text: 'Theft', value: 'Theft' },
        { text: 'Abuse', value: 'Abuse' },
        { text: 'Damage', value: 'Damage' },
        { text: 'Anti-Social Behaviour', value: 'Anti-Social Behaviour' },
        { text: 'Attempted Theft', value: 'Attempted Theft' },
        // { text: 'Test', value: 'Test' },
      ],
      incident: {
        date: localStorage.getItem('incidentDate') || '',
        time: localStorage.getItem('incidentTime') || '',
        siteId: null,
        category: [],
        description: localStorage.getItem('incidentDescription') || '',
        policeRef: localStorage.getItem('incidentPoliceRef') || '',
        evidence: null,
        faces: [],
        evidence: [],
        people: [],
        personsInvolved: '', // New field for persons involved
        description: '',
        accept_declaration: false,
      },
      persons: [],
      faces: [],
      fileDescription: '',
      claim: {
        site: null,
        postcode: null,
        comments: null,
        fuels: [
          {
            id: Math.floor((Math.random() * 10) + 1),
            type: "",
            value: null,
            litres: null,
            pump: null
          }
        ],
        evidence: [
          {
            id: uuidv4(),
            file: null,
            type: "vehicle_image",
            comments: null,
            error: false,
            errorMsg: "",
            permanent: true,
            src: null,
            isPdf: false
          },
          {
            id: uuidv4(),
            file: null,
            type: "till_receipt",
            comments: null,
            error: false,
            errorMsg: "",
            permanent: true,
            src: null,
            isPdf: false
          }
        ],
        accept_agreement: false,
        date: null,
        time: null,
      },
      showDescriptionError: false,
      isSubmitting: false,
    };
  },

  computed: {
    peopleBoxClass() {
      // Check if 'this.persons' is not null and has at least one person
      // return this.persons && this.persons.length > 0 ? 'box-valid' : 'box-invalid'; 
      return this.faces && this.faces.length > 0 ? 'box-valid' : 'box-invalid';
    },

    siteId() {
      // console.log(`COMING FROM COMPUTED SITE ID`, globalState.selectedSiteId);
      return globalState.selectedSiteId;
    },

    maxDate() {
      // Get today's date in YYYY-MM-DD format
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // January is 0!
      let dd = today.getDate();

      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      return `${yyyy}-${mm}-${dd}`;
    },

    descriptionCharacterCount() {
      return this.incident.description.length;
    },

    isFormValid() {
      return this.incident.date && this.incident.time && this.incident.category.length > 0 &&
        this.incident.description && this.incident.evidence && this.incident.accept_declaration;
    }
  },

  watch: {
    siteId(newSiteId, oldSiteId) {
      if (newSiteId !== oldSiteId) {
        this.fetchIncidents(); // Update your incidents based on the new siteId
      }
    },

    'incident.date'() {
      localStorage.setItem('incidentDate', this.incident.date);
    },
    'incident.time'() {
      localStorage.setItem('incidentTime', this.incident.time);
    },
    'incident.description'() {
      localStorage.setItem('incidentDescription', this.incident.description);
    },
    'incident.policeRef'() {
      localStorage.setItem('incidentPoliceRef', this.incident.policeRef);
    },
  },

  async created() {
    this.faces.push(this.faceId);


    // Convert dateFilter from UNIX timestamp to Date
    if (this.dateFilter) {
      const timestamp = parseFloat(this.dateFilter);
      const date = new Date(timestamp * 1000); // Convert to milliseconds

      // Format date and time for the inputs
      const yyyy = date.getFullYear().toString();
      const mm = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
      const dd = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${yyyy}-${mm}-${dd}`;

      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const formattedTime = `${hours}:${minutes}`;

      // Set the date and time for the incident report
      this.incident.date = formattedDate;
      this.incident.time = formattedTime;
    }
    //Setting siteId
    // this.incident.siteId = this.siteId;
    this.incident.siteId = localStorage.getItem('selectedSiteId');

    // console.log('Pending Persons from Incident FORM:', this.pendingPersons);
    if (this.pendingPersons && typeof this.pendingPersons === 'string') {
      this.persons = JSON.parse(this.pendingPersons);
      this.handlePendingPersons(this.persons); // Process pending persons
      // console.log('Processed Persons from pendingPersons:', this.persons);
    }
    // If 'pendingPersons' is not provided, check 'personIds'
    else if (this.personIds && typeof this.personIds === 'string') {
      try {
        const ids = JSON.parse(this.personIds);
        this.persons = ids;
        // console.log('Processed Persons from personIds:', this.persons);
      } catch (e) {
        console.error('Error parsing person IDs:', e);
      }
    }
    // console.log('Persons:', this.personIds);
  },

  // mounted() {
  //   this.faces = faceId;
  // },

  methods: {
    createIncident() {
      // Prevent form submission if already submitting
      if (this.isSubmitting) return;

      // Log the 'pendingPersons' array before making the API call
      console.log('Pending Persons:', this.pendingPersons);

      // Check for empty required fields
      if (!this.incident.date || !this.incident.time || this.incident.category.length === 0 ||
        !this.incident.description || !this.incident.evidence || !this.incident.accept_declaration) {
        this.$error('Please fill in all required fields.');
        return;
      }

      if (globalState.selectedSiteId) {
        this.incident.siteId = globalState.selectedSiteId;
      } else {
        console.error("Site ID not included");
      }

      if (this.incident.description.length < 100) {
        // Prevent submission and show error message
        this.showDescriptionError = true;
        return;
      }

      this.isSubmitting = true; // Disable the submit button

      // Format the time to HH:mm:ss
      const formattedTime = this.formatTime(this.incident.time);

      // Proceed to create FormData and make the API call
      const formData = new FormData();
      formData.append('incident_date', this.incident.date);
      formData.append('incident_time', formattedTime);
      formData.append('types', JSON.stringify(this.incident.category));
      formData.append('description', this.incident.description);
      formData.append('site_id', this.incident.siteId);

      // Append other fields as necessary
      // Append each file to the FormData
      this.incident.evidence.forEach((file, index) => {
        formData.append(`file`, file.file, file.description);
      });

      // // Format and append people data
      // if (this.persons && this.persons.length > 0) {
      //   const peopleData = this.persons.map(person => {
      //     // Check if person object contains more than just an ID
      //     if (person.name || person.description || person.faces) {
      //       return {
      //         ...person,
      //         faces: Array.isArray(person.faces) ? person.faces : [person.faces]
      //       };
      //     }
      //     return person; // Return person object as is (containing only ID)
      //   });
      //   formData.append('people', JSON.stringify(peopleData));
      // }

      formData.append('faces', JSON.stringify(this.faces));

      formData.append('police_ref', this.incident.policeRef);

      //Testing locally

      // const headers = {
      //   'Content-Type': 'multipart/form-data',
      //   'x-vars-claims': '{"id":4,"permissions":["incident.read", "incidents.create", "faces.read", "sites.read", "events-blacklist", "cameras-edit", "cameras-view", "sites-map", "logs-view", "staging-manage","users-edit", "claims-process","claims-view", "clients-edit", "accounting-view", "roles-view", "roles-edit", "permissions-view", "sites-view", "reports-view", "reports-claims-view", "nmop-view", "driveoff-view", "cnaf-view", "parking-view", "scheduler-view", "scheduler-delete", "tablets-view", "scheduler-create", "calls-view", "claims-manage-team"]}',
      // };

      axios.post('https://api.varsanpr.com/customer/faces/incidents', formData, {
        headers: authHeader()
      })
        .then(response => {
          // Handle the successful response here
          console.log(response.data);
          this.$success('Incident report submitted successfully.');

          localStorage.removeItem('incidentDate');
          localStorage.removeItem('incidentTime');
          localStorage.removeItem('incidentDescription');
          localStorage.removeItem('incidentPoliceRef');

          this.navigateToIncidents(); // Navigate to incidents component
        })
        .catch(error => {
          // Handle errors here
          console.error('Error submitting incident report:', error);
          this.$error('There was an error submitting the incident report.');
        }).finally(() => {
          this.isSubmitting = false; // Re-enable the submit button
        });

      //testing locallly

      // axios.post('http://localhost:7071/incidents', formData, {
      //   headers
      // })
      //   .then(response => {
      //     // Handle the successful response here
      //     console.log(response.data);
      //        this.$error('Incident report submitted successfully.');
      //     this.navigateToIncidents(); // Navigate to incidents component
      //   })
      //   .catch(error => {
      //     // Handle errors here
      //     console.error('Error submitting incident report:', error);
      //        this.$error('There was an error submitting the incident report.');
      //   });
    },

    handleFileUpload(event) {
      const newFiles = Array.from(event.target.files).map(file => ({
        file: file,
        description: file.name // Set the file name as the initial value for description
      }));
      this.incident.evidence = [...this.incident.evidence, ...newFiles];
      console.log('Files:', this.incident.evidence);
    },

    removeFile(index) {
      // Remove the file at the given index
      this.incident.evidence.splice(index, 1);
    },

    redirectToComponent() {
      this.$router.push('/face-form');
    },

    handlePendingPersons(pendingPersons) {
      // Ensure 'faces' is always an array
      this.incident.people = pendingPersons.map(person => ({
        name: person.name,
        description: person.description,
        faces: Array.isArray(person.faces) ? person.faces : [person.faces]
      }));
    },

    formatTime(time) {
      // Add ":00" for the seconds part
      return time + ":00";
    },

    navigateToIncidents() {
      // Replace 'Incidents' with the actual name of your incident component route
      this.$router.push({ name: 'Incidents' });
    },

    clearForm() {
      // Reset all the form fields
      this.incident.date = '';
      this.incident.time = '';
      this.incident.category = [];
      this.incident.description = '';
      this.incident.policeRef = '';
      this.incident.evidence = null;
      this.persons = [];
      this.incident.description = '';
      this.showDescriptionError = false;
      this.incident.accept_declaration = false;

      // Clear localStorage
      localStorage.removeItem('incidentDate');
      localStorage.removeItem('incidentTime');
      localStorage.removeItem('incidentDescription');
      localStorage.removeItem('incidentPoliceRef');

      // If there are any other fields, reset them in a similar fashion
    },

    checkDescriptionLength() {
      // Show error if description is less than 100 characters
      this.showDescriptionError = this.incident.description.length < 100;
    },
  }
};
</script>



<style scoped>
.incident-report-container {
  margin-top: 20px;
}

.card {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

@media (min-width: 576px) {
  .col-sm-2 {
    max-width: 20%;
  }

  .col-sm-4 {
    max-width: 40%;
  }

  .col-sm-10 {
    max-width: 80%;
  }

  .box-valid {
    border: 2px solid green;
  }

  .box-invalid {
    border: 2px solid red;
  }

  .people-list {
    padding: 0;
    margin: 0;
  }

  .person-item {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .person-image {
    width: 200px;
    /* Increase width */
    height: 200px;
    /* Increase height */
    object-fit: cover;
    border-radius: 10px;
    /* Optional: for rounded images */
    margin-right: 15px;
  }

  .person-details {
    margin-left: 10px;
  }
}
</style>
